import React, { Component } from "react";
import BackgroundImage from "../repeating_elements/Background-Image.js";

import {scroll_init, scroll_handler, scroll_kill, scroll_update, setPosition} from "../utilities/scroll_handler.js";
// import Slider from "react-slick";
import LazyLoad from "../repeating_elements/Lazy_Load.js";
import ImageCarousel from "../Work/Content_Image_Carousel.js";
import PhoneDisplayCarousel from "../Work/Content_Phone_Display_Carousel.js";
import AsymmetricalImages from "../Work/Content_Asymmetrical_Images.js";
import BoxedImages from "../Work/Content_Boxed_Images.js";
import VideoDisplay from "../Work/Content_Video_Display.js";
import StatisticsDisplay from "../Work/Content_Statistics_Display.js";
import CenteredText from "../Work/Content_Centered_Text.js";
import PageBreak from "../Work/Content_Page_Break.js";
import ModuleBreak from "../Work/Content_Module_Break.js";

const LazyLoadExt = props => {
	return <LazyLoad className="module">{props.children}</LazyLoad>
}

export default class JournalContent extends Component {
	constructor(props){
		super(props);
		this.state = {
			position: null
		};
		this.in_view = false
		this.scroll_init = scroll_init.bind(this);
		this.scroll_handler = scroll_handler.bind(this);
		this.scroll_kill = scroll_kill.bind(this);
		this.scroll_update = scroll_update.bind(this);
		this.setPosition = setPosition.bind(this);
	}
	componentDidMount(){
		if(this.props.data){
			this.scroll_init();
		}
	}
	componentWillUnmount(){
		this.scroll_kill();
	}
	content_modules(data){
		var elements = [];
		//Data types should be made to leverage the same field sets as work.
		//this is not done because it will require data migratin for all posts
		data.forEach((i,x) => {
			if(i.acf_fc_layout === "paragraph"){
				var obj = i.paragraphs.split(" href=").join(" target='_blank' href=");
				elements.push(<div className="journal-module paragraph-module" dangerouslySetInnerHTML={{__html: obj}} />)
			} else if(i.acf_fc_layout === "3_photo_grid"){
				elements.push(
					<div className="journal-module image-lock-2-module">
						<div className="top-photo photo">
							<div className="photo-container">
								<BackgroundImage url={i.stacked_photo_top.sizes.large} loading="lazy"/>
							</div>
						</div>
						<div className="side-photos">
							<div className="photo first-photo">
								<div className="photo-container">
									<BackgroundImage url={i.stacked_photo_bottom.sizes.large} loading="lazy"/>
								</div>
							</div>
							<div className="photo second-photo">
								<div className="photo-container">
									<BackgroundImage url={i.photo_above_test.sizes.large} loading="lazy"/>
								</div>
							</div>
						</div>
					</div>
				)
			} else if(i.acf_fc_layout ==="photo_grid"){
				var data = {};
				data.boxed_images = [];

				i.images.forEach((item)=>{
					let image = {
						video: false,
						image: item.image.url
					};
					data.boxed_images.push(image);
				})

				elements.push(<BoxedImages key={"module-"} data={data} />);


			} else if(i.acf_fc_layout ==="2_photo_grid"){
				elements.push(
					<div className="journal-module image-lock-2-module">
						<div className="side-photos">
							<div className="photo first-photo">
								<div className="photo-container">
									<BackgroundImage url={i.solo_image.sizes.medium_large} loading="lazy"/>
								</div>
							</div>
							<div className="photo second-photo">
								<div className="photo-container">
									<BackgroundImage url={i.over_text_image.sizes.medium_large} loading="lazy"/>
								</div>
							</div>
						</div>
					</div>
				)
			} else if(i.acf_fc_layout ==="slideshow"){
				/*var data = {};
				data.image_carousel = [];
				data.options = [];

				i.slideshow.forEach((item)=>{

					let slide = [{
						image:[{ url: item.image.url}],
						desktop_image: item.image.url
					}];
					data.image_carousel.push(slide);
				});


				elements.push(<ImageCarousel key={"module-"} data={data} />);
				*/


			} else if(i.acf_fc_layout ==="single"){
				var data = {};
				data.poster_image = [];
				data.vimeo_link = i.video_url;

				data.poster_image.sizes = {
					large: i.image.url
				};

				elements.push(<VideoDisplay key={"module"} data={data} />);

			} else if(i.acf_fc_layout ==="text_image"){
				var data = {};
				data.poster_image = [];
				data.vimeo_link = i.video_url;

				data.poster_image.sizes = {
					large: i.image.url
				};

				elements.push(
					<div>
						<VideoDisplay key={"module"} data={data} />
						<div className="journal-module paragraph-module" dangerouslySetInnerHTML={{__html: i.paragraphs}} />
					</div>
				);

			} else if(i.acf_fc_layout ==="centered_text"){

                elements.push(<CenteredText key={"module"} data={i} />);

			} else if(i.acf_fc_layout ==="page_break"){
				elements.push(<PageBreak key={"module-"} /> );
			} else if(i.acf_fc_layout ==="module_break"){
				elements.push(<ModuleBreak key={"module-"} /> );

			} else if(i.acf_fc_layout ==="image_carousel"){
				elements.push(<LazyLoadExt><ImageCarousel key={"module-"} data={i} /></LazyLoadExt>);
			} else if(i.acf_fc_layout ==="phone_display_carousel"){
				elements.push(<LazyLoadExt><PhoneDisplayCarousel key={"module-"} data={i} /></LazyLoadExt> );

			} else if(i.acf_fc_layout ==="asymmetric_images"){
				elements.push(<LazyLoadExt><AsymmetricalImages key={"module-"} data={i} /></LazyLoadExt>);
			} else if(i.acf_fc_layout ==="statistics_display"){
				elements.push(<LazyLoadExt><StatisticsDisplay key={"module-"} data={i} /></LazyLoadExt>);
			}
		});
		return elements;
	}
	render() {
		let in_view = this.in_view ? " in-view" : "";
		if(this.props.data){
			return(
				<div className={ "journal-content grid" + in_view }>
					<div className="grid-m-6 grid-t-16 push-d-1 grid-d-14">
						{this.content_modules(this.props.data)}
					</div>
				</div>
			);
		} else {
			return null
		}
	}
}
