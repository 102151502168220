import React, { Component } from "react";

// import { Link } from 'gatsby';
// const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

// import { request_page } from "../../data/fetch_data.js";

// import { Arrow_Link } from ÷"../buttons/Arrow_Link.js";

import {scroll_init, scroll_handler, scroll_kill, scroll_update, setPosition} from "../utilities/scroll_handler.js";
// import utility_data from "../utilities/browser_information.js";

// let time_out = null;

export default class JournalTitle extends Component {
	constructor(props){
		super(props);
		this.state = {
			position: null
		};
		this.in_view = false
		this.scroll_init = scroll_init.bind(this);
		this.scroll_handler = scroll_handler.bind(this);
		this.scroll_kill = scroll_kill.bind(this);
		this.scroll_update = scroll_update.bind(this);
		this.setPosition = setPosition.bind(this);
		// this.request_page = request_page.bind(this);
	}
	componentDidMount(){
		// this.request_page("data","users/"+this.props.authorID)
		this.scroll_init();
	}
	componentWillUnmount(){
		this.scroll_kill();
	}
	render() {
		let in_view = this.in_view ? " in-view" : "";
		// const post_date = this.props.date;
		return <div className={ "mission-statement grid" + in_view }>
			<div className="interior-mission-statement grid-m-6 grid-t-16 push-d-1 grid-d-14">
				<div className="content-contain">
					<h3 className="styling-header-1">{this.props.title}</h3>
				</div>
			</div>
		</div>;
	}
}