import React, { Component } from 'react';

import { ParallaxProvider } from 'react-scroll-parallax';
import Navigation from "../components/Header/Navigation.js";
import GDPR from '../components/Global/GDPR.js';
import PageTransition from 'gatsby-plugin-page-transitions';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';

import PageBreak from "../components/repeating_elements/page_break.js";

import PanoramicHero from "../components/Global/Panoramic_Hero.js";
import JournalTitle from "../components/Chatbox/Journal_Title.js";
import JournalContent from "../components/Chatbox/Journal_Content.js";
import BreadcrumbContent from "../components/Chatbox/Breadcrumb_Content.js";
import RelatedStories from "../components/Chatbox/Related_Stories.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";

import { request_page } from "../components/data/fetch_data.js"

import { decode } from '../components/utilities/decode.js'



export default class JournalEntry extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
			location: ''
		}
		this.request_page = request_page.bind(this);
		this.journalContent = this.journalContent.bind(this);
	}
	componentDidMount() {
		this.setState({ location: window.location.href })
	}
	journalContent(){
		if(this.state.data.acf.bcp_opening_text){
			return <BreadcrumbContent data={this.state.data.acf} />;
		} else{
			return <JournalContent data={this.state.data.acf.modules ? this.state.data.acf.modules.length > 0 ? this.state.data.acf.modules : false : false} />
		}
	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		if(this.state.isLoaded){

			const data = this.state.data;
			const mobileImage = data.acf.mobile_hero_image || false;
			let mock_slide = [{
				title: false,
				copy: false,
				desktop_image: data.acf.hero_image,
				mobile_image: mobileImage
			}];
			console.log(mobileImage)
			let mock_data = [{
				acf: {
					slides: mock_slide
				}
			}];
			var post_date = new Date(this.state.data.date);
			return (
				<ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />

				<SEO
					title={this.state.data.yoast_meta.yoast_wpseo_title}
					description={this.state.data.yoast_meta.yoast_wpseo_metadesc}
				/>
				<div className="site-contain">
					<Navigation links={this.state.headerLinks} />
					  <div className="page-main">
						<WorkWithUs />
					<div className={"page journal-entry " + page_ready}>
						<PanoramicHero slides={mock_data} />
						<JournalTitle title={decode(this.state.data.title.rendered)} authorID={this.state.data.author} date={post_date} />
						<div className="article-share">
							<h2 className="styling-eyebrow">Share on</h2>
							<ul>
								<li>
									<a title="Share on Twitter" target="_blank" rel="noopener noreferrer" href={"https://twitter.com/home?status=" + this.state.location}>
										<span>Tw</span>
									</a>
								</li>
								<li>
									<a title="Share on Facebook" target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + this.state.location}>
										<span>Fb</span>
									</a>
								</li>
							</ul>
						</div>
						{this.journalContent()}
						<PageBreak />
						<RelatedStories />
						<PageBreak />
						<GetInTouchFooter />
						<Footer links={this.state.footerLinks}/>
					</div>
					</div>
				</div>
			</PageTransition></ParallaxProvider>
			)
		} else{

			return (
				<div></div>
			)
		}
	}
}
