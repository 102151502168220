import React, { Component } from "react";
import BackgroundImage from "../repeating_elements/Background-Image.js";

import {scroll_init, scroll_handler, scroll_kill, scroll_update, setPosition} from "../utilities/scroll_handler.js";


export default class BreadcrumbContent extends Component {
	constructor(props){
		super(props);
		this.state = {
			position: null
		};
		this.in_view = false
		this.scroll_init = scroll_init.bind(this);
		this.scroll_handler = scroll_handler.bind(this);
		this.scroll_kill = scroll_kill.bind(this);
		this.scroll_update = scroll_update.bind(this);
		this.setPosition = setPosition.bind(this);
	}
	componentDidMount(){
		if(this.props.data){
			this.scroll_init();
		}
	}
	componentWillUnmount(){
		this.scroll_kill();
	}
	content_text(text){
		if(text){
			const content = text.split(" href=").join(" target='_blank' href=");
			return <div className="journal-module paragraph-module" dangerouslySetInnerHTML={{__html: content}} />
		}
	}
	content_image_grid(top, left, right){
		if(top){
			return(
				<div className="journal-module image-lock-2-module">
					<div className="top-photo photo">
						<div className="photo-container">
							<BackgroundImage url={top.sizes.medium_large} loading="lazy"/>
						</div>
					</div>
					<div className="side-photos">
						<div className="photo first-photo">
							<div className="photo-container">
								<BackgroundImage url={left.sizes.medium_large} loading="lazy"/>
							</div>
						</div>
						<div className="photo second-photo">
							<div className="photo-container">
								<BackgroundImage url={right.sizes.medium_large} loading="lazy"/>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
	render() {
		let in_view = this.in_view ? " in-view" : "";
		if(this.props.data){
			return(
				<div className={ "journal-content grid" + in_view }>
					<div className="grid-m-6 grid-t-16 push-d-1 grid-d-14">
						{this.content_text(this.props.data.bcp_opening_text)}
						{this.content_image_grid(this.props.data.bcp_photo_top, this.props.data.bcp_photo_left, this.props.data.bcp_photo_right)}
						{this.content_text(this.props.data.bcp_post_text)}
					</div>
				</div>
			);
		} else {
			return null
		}
	}
}
