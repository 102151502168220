import React, { Component } from "react";

import { Link } from 'gatsby';
import BackgroundImage from "../repeating_elements/Background-Image.js";
import LazyLoad from "../repeating_elements/Lazy_Load.js";
// const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

import { request_page } from "../data/fetch_data.js";


export default class RelatedStories extends Component {
	constructor(props){
		super(props)
		this.state = {
			data: null
		}
		this.request_page = request_page.bind(this);
	}
	componentDidMount(){
		this.request_page("data","posts?categories=31&per_page=3");
	}
	decode(encodedStr) {
		var parser = new DOMParser();
		var dom = parser.parseFromString(
			'<!doctype html><body>' + encodedStr,
			'text/html');
		var decodedString = dom.body.textContent;
		return decodedString
	}
	render() {
		if(this.state.data){
			console.log(this.state.data[0].acf.thumbnail)
			return(
				<LazyLoad>
					<div className="related-stories grid">
						<h3 className="styling-eyebrow grid-m-6 grid-t-16 grid-d-16">Related stories</h3>
						<Link to={"/chatbox/"+this.state.data[0].slug} className="related-article grid-m-6 grid-t-1-3 grid-d-1-3" title="Access Journal Item">
							<div className="thumbnail">
								<BackgroundImage url={this.state.data[0].acf.thumbnail.sizes.medium} alt={this.state.data[0].slug} loading="lazy"/>
							</div>
							<h4 className="styling-eyebrow">{this.decode(this.state.data[0].title.rendered)}</h4>
						</Link>
						<Link to={"/chatbox/"+this.state.data[1].slug} className="related-article grid-m-6 grid-t-1-3 grid-d-1-3" title="Access Journal Item">
							<div className="thumbnail">
								<BackgroundImage url={this.state.data[1].acf.thumbnail.url} alt={this.state.data[1].slug} loading="lazy"/>
							</div>
							<h4 className="styling-eyebrow">{this.decode(this.state.data[1].title.rendered)}</h4>
						</Link>
						<Link to={"/chatbox/"+this.state.data[2].slug} className="related-article grid-m-6 grid-t-1-3 grid-d-1-3" title="Access Journal Item">
							<div className="thumbnail">
								<BackgroundImage url={this.state.data[2].acf.thumbnail.url} alt={this.state.data[2].slug} loading="lazy"/>
							</div>
							<h4 className="styling-eyebrow">{this.decode(this.state.data[2].title.rendered)}</h4>
						</Link>
					</div>
				</LazyLoad>
			);
		}
		return <div className={ "related-stories grid" }>
		</div>;
	}
}
